import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "overlay"
}
const _hoisted_2 = { class: "vue-close-button" }
const _hoisted_3 = {
  id: "overlay-body",
  class: "overlay-body height-is-100"
}
const _hoisted_4 = { class: "content-scroller-assessment-overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.active)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["overlay-container autoHeight maxHeight noMinHeight", {'with-max-width': _ctx.componentHasMaxwidth(_ctx.componentToRender)}])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("i", {
              class: "pd-vue-overlay-close icon icon-icon_close icon-size-30",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentToRender), {
                param_container: _ctx.paramContainer,
                onClose: _ctx.close
              }, null, 40, ["param_container", "onClose"]))
            ])
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}