import {defineStore} from 'pinia'
import {IProjectList, IProject, ISearch} from '@/views/helpers/Management.js'
import {Ref, ref} from 'vue'

export const useFolderStore = defineStore('folder_list', () => {
	const folderList: Ref<IProjectList | null> = ref(null)
	const folderListCurrent: Ref<IProjectList | null> = ref(null)
	function setFolderList(to: IProjectList) {
		folderList.value = to
		if (!folderListCurrent.value) folderListCurrent.value = to
	}
	function setFolderListCurrent(to: IProjectList) {
		folderListCurrent.value = to
	}

	const folderListBreadcrumbs: Ref<Array<IProject>> = ref([])
	function setBreadcrumbs(to: Array<IProject> | null) {
		if (to) folderListBreadcrumbs.value = to
		else folderListBreadcrumbs.value = []
	}

	function deleteFolder(id: number) {
		if (!folderList.value) return
		findAndDelete(id, folderList.value)

		function findAndDelete(id: number, ptr: IProjectList) {
			for (let idx = 0; idx < ptr.children.length; idx++) {
				if (ptr.children[idx].project.id == id) {
					ptr.children.splice(idx, 1)
					return
				} else {
					findAndDelete(id, ptr.children[idx])
				}
			}
		}
	}

	function deleteCollection(id: number) {
		if (!folderList.value) return
		findAndDelete(id, folderList.value)

		function findAndDelete(id: number, ptr: IProjectList) {
			for (const child of ptr.children) {
				if (!child.searches) break
				for (let idx = 0; idx < child.searches.length; idx++) {
					if (child.searches[idx].id == id) {
						child.searches.splice(idx, 1)
						return
					}
				}
				findAndDelete(id, child)
			}
		}
	}

	function addFolder(toId: number | null, folder: IProject) {
		if (!folderList.value) return
		const newFolder: IProjectList = {
			project: folder,
			children: [],
			searches: [],
			parent: null,
		}

		if (!toId) {
			folderList.value?.children.push(newFolder)
		} else {
			appendToId(toId, newFolder, folderList.value)
		}

		function appendToId(id: number, obj: IProjectList, parent: IProjectList) {
			parent.children.forEach((el) => {
				if (el.project.id == id) {
					el.children.push(obj)
					return
				}
				if (el.children.length < 1) {
					return
				}
				appendToId(id, obj, el)
			})
		}
	}

	function addCollection(collection: ISearch) {
		if (!folderList.value) return
		appendToId(collection.project, collection, folderList.value)

		function appendToId(id: number, collection: ISearch, parent: IProjectList) {
			parent.children.forEach((el) => {
				if (el.project.id == id) {
					if (!el.searches) {
						el.searches = [collection]
					} else {
						el.searches.push(collection)
					}
					return
				}
				if (el.children.length < 1) {
					return
				}
				appendToId(id, collection, el)
			})
		}
	}

	return {
		folderList,
		setFolderList,
		folderListCurrent,
		setFolderListCurrent,
		folderListBreadcrumbs,
		setBreadcrumbs,
		deleteFolder,
		deleteCollection,
		addFolder,
		addCollection,
	}
})

export const useFolderListActiveId = defineStore('folder_active_id', () => {
	const activeId: Ref<number | null> = ref(null)
	function setActiveId(to: number | null) {
		activeId.value = to
	}

	return {activeId, setActiveId}
})
