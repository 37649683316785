
import {defineComponent} from 'vue'
import {SearchStepMixin} from '@/views/mixins/SearchStepMixin'
import {PermissionMixin} from '@/views/mixins/PermissionMixin'
import {LoaderMixin} from '@/views/mixins/LoaderMixin'
import {fetchPOST} from '@/views/mixins/PredoriFetch'
import OverlayComponentRenderer from '@/components/OverlayComponentRenderer.vue'
import router from '@/router'

export default defineComponent({
	name: 'SelectSearchTypeView',
	mixins: [PermissionMixin, SearchStepMixin, LoaderMixin],
	components: {OverlayComponentRenderer},
	computed: {
		projectId() {
			// We will see what `params` is shortly
			return this.$route.params.projectId
		},
	},
	mounted() {
		this.loadData()
	},
	methods: {
		async loadData() {
			// this is basically an empty call, we only do it to check if the user is logged in
			const url = '/spa/api/search/select_search_type'
			const responseJSON = await this.handleGet(url)
			if (responseJSON === null) {
				return
			}
		},

		async submit(searchTypeValue: string) {
			if (searchTypeValue === 'Predori X') {
				const url = '/spa/api/x/create'
				const res = await fetchPOST<{status: 'success' | 'error'; body: {id: number}}>({
					url: url,
				})

				if (res == undefined) return
				if (res.status !== 'success') return
				router.push(`/x/${res.body.id}`)
			} else {
				let url = '/spa/api/search/select_search_type'
				if (this.projectId) {
					url = `${url}/${this.projectId}`
				}

				const data = {'Search-Type-Btn': searchTypeValue}

				this.handlePost(url, data, this.$router)
			}
		},
	},
})
