export const LoaderMixin = {
    methods: {
        showLoader() {
            const loader = document.getElementById('pd-loader')
            if (loader) {
                loader.style.display = 'flex';
            }
        },
        hideLoader() {
            const loader = document.getElementById('pd-loader')
            if (loader) {
                loader.style.display = 'none';
            }
        },
    }
}