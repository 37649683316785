import { createApp } from 'vue'
import VueClipboard from 'vue3-clipboard'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import CKEditor from '@ckeditor/ckeditor5-vue';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueEventer from 'vue-eventer';
import {RouteLocationNormalized} from "vue-router";

// Set up a global error handler
window.addEventListener('error', (event) => {
    PdLogger.errorFromException(event.error);
});


export const app = createApp(App);
app.use(router)
app.config.globalProperties.$eventBus = new VueEventer();

app.use(VueClipboard, {  autoSetContainer: true,
    appendToBody: true,})

app.use( CKEditor)

app.config.globalProperties.$snackbarStack = [];

// make eventbus available to external components
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.document.$spaEventBus = app.config.globalProperties.$eventBus;
app.provide('eventBus', app.config.globalProperties.$eventBus);


// register custom v-click-outside directive
app.directive('click-outside', {
    mounted(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});
// register custom v-autofocus directive
app.directive('autofocus', {
    mounted(el) {
        el.focus()
    }
})

// Vue Pinia
import {createPinia} from 'pinia'
const pinia = createPinia()
app.use(pinia)

app.mount('#app')


// bind router before  events
router.beforeEach((to, from, next) => {
    let title = 'Predori - Patent Search Done Right';
    if (to['meta'] && to['meta']['title']) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title = to.meta['title'].toString();
    }
    document.title = title;

    next()
})

function resetEventStroreAndPreserveGlobalEvents() {
    // events we want to preserve during route changes
    const globalEventKeys = ['update-right-navbar-data', 'is_page_dirty', 'set-max-step', 'remember-dirty-before-save'];
    const globalEvents = {};
    // backup events we want to have global
    globalEventKeys.forEach((event: string) => {
        if (app.config.globalProperties.$eventBus.events[event]) {
            globalEvents[event] = app.config.globalProperties.$eventBus.events[event];
        }
    });
    // reset the eventbus
    app.config.globalProperties.$eventBus.events = [];
    // restore global events
    Object.keys(globalEvents).forEach((event: string) => {
        app.config.globalProperties.$eventBus.events[event] = globalEvents[event];
    })
}

import {useProjectListActiveId} from '@/stores/managementProjectStore'
import {useFolderListActiveId} from '@/stores/managementFolderStore'
import PdLogger from "./lib/PdLogger";
const projectActiveId = useProjectListActiveId()
const folderActiveId = useFolderListActiveId()
// bind router after events
router.afterEach((to, from, next) => {
	// Prevent resetting the eventBus when navigating between projects/folders
	// Set correct current active Folder/Project ID when navigating wiht forward/backward browser buttons
	if (from.name === 'project_list' || from.name === 'project_list_id') {
		if (to.name === 'project_list' || to.name === 'project_list_id') {
			const nextProjectId = to.params.projectId ? to.params.projectId : null
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			projectActiveId.activeId = nextProjectId
			return
		}
	}
	if (from.name === 'folder_list' || from.name === 'folder_list_id') {
		if (to.name === 'folder_list' || to.name === 'folder_list_id') {
			const nextFolderId = to.params.folderId ? to.params.folderId : null
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			folderActiveId.activeId = nextFolderId
			return
		}
	}
    // console.log('router', to, from, next);
    resetEventStroreAndPreserveGlobalEvents();

    // make eventbus available to external components
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.document.$spaEventBus = app.config.globalProperties.$eventBus;

    const checkForRightNavbar = function(to: RouteLocationNormalized) {
        const elem = document.getElementById('site-content');
        if(elem === null){
            return;
        }

        // check if the right navbar is set and set the navbar handling class accordingly
        if (to &&
            to.matched[0] &&
            to.matched[0]['components'] &&
            to.matched[0]['components']['right-menu-router'] &&
            to.matched[0]['components']['right-menu-router'].name == 'RightNavbar'
        ) {
            elem.classList.add('right-menu-is-rendered');
        } else {
            elem.classList.remove('right-menu-is-rendered');
        }
    }
    const updateLeftNavbar = function (to: RouteLocationNormalized) {
		const menuItems = document.querySelectorAll('.main-menu-list-item')
		menuItems.forEach((el) => {
			el.classList.remove('active')
		})
		let t: null|string = null;
		switch (to.name) {
            case 'home':
                t = 'quick-start'
                break
			case 'project_list':
			case 'project_list_id':
				t = 'my-projects'
				break
			case 'folder_list':
			case 'folder_list_id':
				t = 'my-folders'
				break
            case 'tag_list':
                t ='my-tags'
                break
			default:
                t = null;
		}
        if (t !== null) {
            const activeItem = document.getElementById(`main-menu-list-item__${t}`)
            if (activeItem) activeItem.classList.add('active')
        }
	}
	checkForRightNavbar(to)
	updateLeftNavbar(to)
});
