import {Router} from "vue-router";
import router from "@/router";
import {pushSnackBarItem, showSnackBarItem, snackBarHelper, SnackbarType} from "@/views/helpers/SnackbarHelper";
import PdLogger from "@/lib/PdLogger";
import { _getCsrfHeader } from "@/views/mixins/PredoriFetch";

export enum SEARCH_TYPES {
    STATE_OF_THE_ART = 'State of the Art',
    FTO = 'FTO',
    ULTRA_QUICK = 'Ultra Quick',
    SINGLE_DOCUMENT_SEARCH = 'Single Document Search',
    FIELDED_SEARCH = 'Fielded Search',
    INVALIDATION = 'Invalidation',
    PATENTABILITY = 'Patentability',
    TECHNOLOGY_MONITORING = 'Technology Monitoring',
    DOCUMENT_MONITORING = 'Document Monitoring',
    FOLDER_COLLECTION = 'Folder Collection',
    PREDORI_X = 'Predori X'
}

export function isMatrixSearch(searchType) {
    const invalidationSearchTypeList = [SEARCH_TYPES.INVALIDATION, SEARCH_TYPES.PATENTABILITY, SEARCH_TYPES.FTO, SEARCH_TYPES.TECHNOLOGY_MONITORING];
    return invalidationSearchTypeList.indexOf(searchType) > -1;
}


export function isListSearch(searchType) {
    // rigth now we only have the two versions matrix and list search
    return !isMatrixSearch(searchType);
}

export const SearchStepMixin = {
    methods: {
        showxLoader() {
            const loader = document.getElementById('pd-loader')
            if (loader) {
                loader.style.display = 'flex';
            }
        },
        hidexLoader() {
            const loader = document.getElementById('pd-loader')
            if (loader) {
                loader.style.display = 'none';
            }
        },
        // @todo todo handle all errors that can occure here, not only 403
        validateResponse(response) {
            //handle if a redirect was returned from the backend - right now we handle only a redirect to the login page
            if (response.status === 200) {
                if (response.redirected) {

                    if (response.url && response.url.indexOf('/account/login/?next=') > -1) {
                        const hash = window.location.hash;
                        let redirectUrl = '/account/login/';
                        if (hash) {
                            redirectUrl += '?next=' + hash;
                        }
                        location.href = redirectUrl;
                        return false;
                    }
                }
            }

            switch(response.status) {
                case 403:
                    location.href = '/login/';
                    return false;
                    break;
                case 500:
                    alert("An Internal server error occured");
                    return false;
                    break;
            }


            return true;
        },
        async handleGet(url: string) {
            let response;
            try {
                this.showxLoader();
				const headers = _getCsrfHeader()
                const response = await fetch(url, {headers: headers});

                if (this.validateResponse(response) !== true) {
                    this.hidexLoader();
                    return {};
                }


                const responseJSON = await response.json();


                if (responseJSON['error'] && responseJSON['error'] === true) {
                    console.log('error from backend was received in get request');
                    if (responseJSON['snack_data']) {
                        pushSnackBarItem(snackBarHelper(responseJSON['snack_data']['title'], responseJSON['snack_data']['text'], responseJSON['snack_data']['type']));
                    }

                    if (responseJSON['redirect_url'] && responseJSON['redirect_url']) {
                        this._handleRedirectUrl(responseJSON['redirect_url']);
                    }
                }

                if (responseJSON['redirect_to']) {
                    console.log('redirect_to was set in GET response, redirecting to: ', responseJSON['redirect_to']);
                    this._handleRedirectUrl(responseJSON['redirect_to']);
                    return;
                }

                if (responseJSON['right_navbar_data']) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.$eventBus.$emit('update-right-navbar-data', responseJSON['right_navbar_data']);
                }

                this.hidexLoader();
                return responseJSON
            } catch (e) {
                if (e instanceof Error) {
                    // if response was not json something went wrong
                    const contentType = response.headers.get("content-type");
                    let additionalText = 'Content Type was: ' + contentType
                    if (!contentType?.includes("application/json")) {
                        const text = await response.text();
                        additionalText += `/n Response was not JSON. Server delivered:: ${text}`;
                    }

                    PdLogger.errorFromException(e, additionalText);
                }

                showSnackBarItem(snackBarHelper('Error', 'We are sorry but an unknown error occurred while loading your data. Please reload the page and try again.', SnackbarType.ERROR));
                this.hidexLoader();
                return null;
            }

        },
        async handlePost(url: string, data: any, router: Router) {
            let response;
            try {
                this.showxLoader();

                const formData: FormData = new FormData();
                Object.keys(data).forEach((key: string) => formData.append(key, data[key].toString()));
				const headers = _getCsrfHeader()

				const request = new Request(url, {
					method: 'POST',
					body: formData,
					headers: headers,
				})

                // console.log('start fetch', url);
                response = await fetch(request);
                this.validateResponse(response);
                const responseJSON = await response.json();

            // if silent post was enabled we dont want to do any redirect or so.
            if (data['silent_post']) {
                this.hidexLoader();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.$eventBus.$emit('save-data-silent-finished', this.baseRequestData);
                return;
            }

                if (responseJSON['error'] && responseJSON['error'] === true){
                    console.log('return')
                    return responseJSON;
                }

                this._handleRedirectUrl(responseJSON['redirect_to']);
            } catch (e) {
                if (e instanceof Error) {

                    // if response was not json something went wrong
                    const contentType = response.headers.get("content-type");
                    let additionalText = 'Content Type was: ' + contentType
                    if (!contentType?.includes("application/json")) {
                        const text = await response.text();
                        additionalText += `/n Response was not JSON. Server delivered:: ${text}`;
                    }

                    PdLogger.errorFromException(e, additionalText);
                }
                showSnackBarItem(snackBarHelper('Error', 'We are sorry but an unknown error occurred while processing your data. Please reload the page and try again.', SnackbarType.ERROR));
                this.hidexLoader();
                return null;
            }
        },
        _handleRedirectUrl(recievedRedirect) {
            const apiPrefix = '/spa/api';
            const redirectUrl = recievedRedirect.startsWith(apiPrefix) ? recievedRedirect.slice(apiPrefix.length) : recievedRedirect;
            console.log('red', redirectUrl);
            // if route is resolvable, stay in the spa, else redirect to the django app
            const resolved = router.resolve({path: redirectUrl});
            if (resolved.matched.length > 0) {
                console.log('use spa routing');
                router.push(redirectUrl);
            } else {
                console.log('use location.href');
                location.href = redirectUrl;
            }
        },
        ensureUrlHasApiPrefix(url) {
            const apiPrefix = '/spa/api';
            const redirectUrl = url.startsWith(apiPrefix) ? url  : apiPrefix + url ;
            return redirectUrl;
        },
        renderSearchAsInvalidation(searchType) {
            return isMatrixSearch(searchType);
            // const invalidationSearchTypeList = [SEARCH_TYPES.INVALIDATION, SEARCH_TYPES.PATENTABILITY, SEARCH_TYPES.FTO, SEARCH_TYPES.TECHNOLOGY_MONITORING];
            // return invalidationSearchTypeList.indexOf(searchType) > -1;
        }
    },
}
