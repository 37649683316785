import {defineStore} from 'pinia'
import {IProjectList, IProject, ISearch} from '@/views/helpers/Management.js'
import {Ref, ref} from 'vue'

export const useProjectStore = defineStore('project_list', () => {
	const projectList: Ref<IProjectList | null> = ref(null)
	const projectListCurrent: Ref<IProjectList | null> = ref(null)
	function setProjectList(to: IProjectList) {
		projectList.value = to
		if (!projectListCurrent.value) projectListCurrent.value = to
	}
	function setProjectListCurrent(to: IProjectList) {
		projectListCurrent.value = to
	}

	const projectListBreadcrumbs: Ref<Array<IProject>> = ref([])
	function setBreadcrumbs(to: Array<IProject> | null) {
		if (to) projectListBreadcrumbs.value = to
		else projectListBreadcrumbs.value = []
	}

	function deleteProject(id: number) {
		if (!projectList.value) return
		findAndDelete(id, projectList.value)

		function findAndDelete(id: number, ptr: IProjectList) {
			for (let idx = 0; idx < ptr.children.length; idx++) {
				if (ptr.children[idx].project.id == id) {
					ptr.children.splice(idx, 1)
					return
				} else {
					findAndDelete(id, ptr.children[idx])
				}
			}
		}
	}

	function deleteSearch(id: number) {
		if (!projectList.value) return
		findAndDelete(id, projectList.value)

		function findAndDelete(id: number, ptr: IProjectList) {
			for (const child of ptr.children) {
				if (!child.searches) break
				for (let idx = 0; idx < child.searches.length; idx++) {
					if (child.searches[idx].id == id) {
						child.searches.splice(idx, 1)
						return
					}
				}
				findAndDelete(id, child)
			}
		}
	}

	function addProject(toId: number | null, project: IProject) {
		if (!projectList.value) return
		const newProject: IProjectList = {
			project: project,
			children: [],
			searches: [],
			parent: null,
		}

		if (!toId) {
			projectList.value?.children.push(newProject)
		} else {
			appendToId(toId, newProject, projectList.value)
		}

		function appendToId(id: number, obj: IProjectList, parent: IProjectList) {
			parent.children.forEach((el) => {
				if (el.project.id == id) {
					el.children.push(obj)
					return
				}
				if (el.children.length < 1) {
					return
				}
				appendToId(id, obj, el)
			})
		}
	}

	function addSearch(search: ISearch) {
		if (!projectList.value) return
		appendToId(search.project, search, projectList.value)

		function appendToId(id: number, search: ISearch, parent: IProjectList) {
			parent.children.forEach((el) => {
				if (el.project.id == id) {
					if (!el.searches) {
						el.searches = [search]
					} else {
						el.searches.push(search)
					}
					return
				}
				if (el.children.length < 1) {
					return
				}
				appendToId(id, search, el)
			})
		}
	}

	return {
		projectList,
		setProjectList,
		projectListCurrent,
		setProjectListCurrent,
		projectListBreadcrumbs,
		setBreadcrumbs,
		deleteProject,
		deleteSearch,
		addProject,
		addSearch,
	}
})

export const useProjectListActiveId = defineStore('project_active_id', () => {
	const activeId: Ref<number | null> = ref(null)
	function setActiveId(to: number | null) {
		activeId.value = to
	}

	return {activeId, setActiveId}
})
