<template>
	<div
		class="is-inline-block main-content {% block main_content_classes %}{% endblock %}"
	>
		<router-view />
	</div>

	<router-view name="right-menu-router"></router-view>
	<div class="is-clearfix"></div>
</template>

<style lang="scss">
@use '@/assets/css/colors' as c;
.vue-notification {
	font-size: 16px !important;
	color: #ffffff !important;
	width: 400px !important;
	&.warn {
		background: #ffb648 !important;
		border-left-color: #f48a06 !important;
	}
}

.predori-notification {
	// margin: 0 5px 5px;
	padding: 10px;
	width: 420px !important;
	margin: 10px 10px 0 10px;
	position: absolute;
	top: 80px;
}
</style>
